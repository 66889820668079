import * as React from 'react'

import Page from '../components/Common/Page'
import IndexLayout from '../layouts'
import JobsOpeningTemplateSection from '../components/pages/CareerPage/JobsOpeningTemplateSection'
import RelatedJobOpenings from '../components/pages/CareerPage/RelatedJobOpenings'
import JobApplicationForm from '../components/pages/CareerPage/JobApplicationForm'

const CareerTemplate: React.FC<any> = ({ pageContext: { data, all } }) => (
  <IndexLayout transparent>
    <Page>
      <JobsOpeningTemplateSection data={data} />
      <JobApplicationForm careers={[data]} />
      <RelatedJobOpenings all={all} />
    </Page>
  </IndexLayout>
)

export default CareerTemplate
