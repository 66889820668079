/* eslint-disable prettier/prettier */
import * as React from 'react';
import tw, { styled } from 'twin.macro';
import { TCareer } from '../../../pages/career';
import JobCard from './JobCard';

const StyledContainer = styled.section(() => [tw`relative bg-[#F3F3F4]`]);

const Column = styled.div(() => [tw`pb-18 flex justify-center md:max-w-pageContainerSize w-full px-pageContainerSpace xl:px-0 mx-auto gap-12 grid justify-center grid-cols-1 md:grid-cols-3`]);

const Title = styled.p(() => [
  tw`flex w-full text-2xl md:text-[32px] justify-center font-bold text-spaceGray pt-12 mb-16`
]);

interface RelatedJobOpeningsProps {
  className?: string;
  all: TCareer[];
}

const RelatedJobOpenings: React.FC<RelatedJobOpeningsProps> = ({ className, all }) => (
  <StyledContainer className={className}>
    <Title>We also open</Title>
    <Column>
      {all && all.slice(0, 3).map((job) => (
        <JobCard
          key={job.node.title}
          job={job}
        />
      ))}
    </Column>
  </StyledContainer>
);

export default RelatedJobOpenings;
