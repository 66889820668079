import * as Yup from 'yup';

export interface ApplyJobData {
  position: string;
  full_name: string;
  email: string;
  phone_number: string;
  expected_salary: string;
}

export const InitialValues: ApplyJobData = {
  position: '',
  full_name: '',
  email: '',
  phone_number: '',
  expected_salary: '',
};

export const ValidationSchema = Yup.object().shape({
  position: Yup
    .string()
    .required('Please specify the position you would like to apply'),
  full_name: Yup
    .string()
    .required('Please let us know you name'),
  email: Yup
    .string()
    .email('Invalid email format')
    .required('Email is required'),
  phone_number: Yup
    .string()
    // .matches(/^0\d{9}$/, 'Invalid phone number format')
    .required('Phone number is required'),
  expected_salary: Yup
    .string()
    .max(6)
    .required('Please specify your expected salary'),
});