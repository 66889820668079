import * as React from 'react'
import tw, { styled } from 'twin.macro'
import { marked } from 'marked'

const StyledContainer = styled.section(() => [
  tw`relative md:max-w-pageContainerSize w-full px-pageContainerSpace xl:px-0 mx-auto pt-6 md:pt-10 pb-0 lg:pb-0 sm:mb-20 md:mb-14 xl:mb-0`
])

const Row = styled.div(() => [tw`grid grid-cols-2`])

const Col = styled.div(() => [tw`col-span-2 lg:col-span-1 flex flex-col`])

const JobsOpening = styled.h3(() => [tw`font-bold text-[16px] md:text-2xl mb-4 md:mb-6 text-orange mt-0`])

const Title = styled.h2(() => [
  tw`flex w-full text-[24px] lg:text-[40px] mt-0 lg:justify-start font-bold text-spaceGray md:mb-8 mb-2`
])

const Contact = styled.p(() => [tw`text-[16px] font-bold text-spaceGray mb-12 mt-0 md:mt-6`])

const EmailUs = styled.span(() => [tw`mr-3`])

const Email = styled.a(() => [tw`text-orange`])

const UpdatedAt = styled.p(() => [tw`font-semibold text-[16px] text-spaceGray mb-5 md:mb-9`])

const Subtitle = styled.p(() => [tw`text-[14px] md:text-[16px] font-bold text-spaceGray mb-2`])

const RichText = styled.div(() => [tw`pl-5 mb-6 text-[14px] md:text-[16px]`])

interface CareerDataProps {
  title: string
  createdAt: string
  updatedAt: string
  description: string
  client: string
  qualifications: string
  jobResponsibilities: string
}

interface JobsOpeningTemplateSectionProps {
  className?: string
  data: CareerDataProps
}

const formatDate = (dateTime: Date) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
  if (dateTime) {
    const month = months[dateTime.getMonth()]
    return `${month} ${dateTime.getDate()} ${dateTime.getFullYear()}`
  }
  return undefined
}

const getMarkdownText = (markdown: string) => {
  const rawMarkup = marked.parse(markdown)
  return { __html: rawMarkup }
}

const JobsOpeningTemplateSection: React.FC<JobsOpeningTemplateSectionProps> = ({ className, data }) => (
  <StyledContainer className={className}>
    <Row>
      <Col>
        <JobsOpening>Jobs opening</JobsOpening>
        <Title>{data?.title}</Title>
        <UpdatedAt>{formatDate(new Date(data?.updatedAt))}</UpdatedAt>
        <Subtitle>Qualifications :</Subtitle>
        {!!data?.qualifications && <RichText dangerouslySetInnerHTML={getMarkdownText(data.qualifications)} />}
        <Subtitle>Job Responsibilities :</Subtitle>
        {!!data?.jobResponsibilities && <RichText dangerouslySetInnerHTML={getMarkdownText(data.jobResponsibilities)} />}
      </Col>
    </Row>
    {/* <Contact>
      <EmailUs>Submit your CV, digital files or links to</EmailUs> <Email href="mailto:wanttowork@orangecapinnovative.com">wanttowork@orangecapinnovative.com</Email>
    </Contact> */}
  </StyledContainer>
)

export default JobsOpeningTemplateSection
