/* eslint-disable prettier/prettier */
import React, { useEffect, useState }  from 'react';
import tw, { styled } from 'twin.macro';
import { useFormik } from 'formik';
import { ApplyJobData, InitialValues as initialValues, ValidationSchema as validationSchema } from '../../../input-form-schema/apply-job';
import { TeamName } from '../../../utils/enums';

import OCILogo from '../../../assets/logo/OCI_color.svg';
import CheckMarkIcon from '../../../assets/common/checkmark.png';

const StyledContainer = styled.section(() => [tw`relative`]);

// const Column = styled.div(() => [tw`pb-18 flex justify-center md:max-w-pageContainerSize w-full px-pageContainerSpace xl:px-0 mx-auto gap-12 grid justify-center grid-cols-1 md:grid-cols-3`]);

const Title = styled.p(() => [
  tw`flex w-full text-2xl md:text-[32px] justify-center font-bold text-spaceGray mb-16 text-orange`
]);

const InputLabel = styled.label(() => [
  tw`text-spaceGray w-5/6 text-[18px] leading-7 font-light`,
]);

// const InputLabel = styled.label`
//   color: #283941;
//   font-size: 18px;
// `;

const InputMessage = styled.span`
  color: red;
  font-size: 16px;
`;

const Input = styled.input`
  outline: none;
  color: #283941;
  font-size: 18px;
  border-bottom-width: 1px;
  border-color: #E46B25;
  padding: 2px;
`;

const InputSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
  margin-bottom: 32px;
`;

interface RelatedJobOpeningsProps {
  careers: {
    client: string;
    createdAt: string;
    updatedAt: string;
    description: string;
    jobResponsibilities: string;
    qualifications: string;
    team: {
      id: number;
      name: TeamName;
    };
    title: string;
  }[];
}

const JobApplicationForm: React.FC<RelatedJobOpeningsProps> = ({ careers }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isErrorSubmitForm, setIsErrorSubmitForm] = useState(false);

  const [resumeFile, setResumeFile] = useState<File | null>(null);
  const [resumeError, setResumeError] = useState(false);

  const [portfolioFile, setPortfolioFile] = useState<File | null>(null);


  const onAddFile = (e: React.ChangeEvent<HTMLInputElement>, fileType: 'resume' | 'portfolio') => {
    if (e.target.files) {
      const file = e.target.files[0];
      if (fileType === 'resume') {
        setResumeError(false);
        setResumeFile(file);
      }
      if (fileType === 'portfolio') {
        setPortfolioFile(file);
      } 
    }
  }

  const onSubmit = async () => {
    console.log('submitted');
    if (!resumeFile) {
      setResumeError(true);
      return;
    }
    const formData = new FormData();
    formData.append('position', values.position);
    formData.append('full_name', values.full_name);
    formData.append('phone_number', values.phone_number);
    formData.append('email', values.email);
    formData.append('expected_salary', values.expected_salary);
    formData.append('resume', resumeFile);
    if (portfolioFile) {
      formData.append('portfolio', portfolioFile);
    }
    const response = await fetch('https://us-central1-orange-cap-landing-ee0bb.cloudfunctions.net/careers', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      body: formData,
    });
    if (response.status === 200) {
      setIsErrorSubmitForm(false);
      setSubmitting(false);
      setIsSubmitted(true);
      setTimeout(() => setIsSubmitted(false), 8000);
    } else {
      setSubmitting(false);
      setIsErrorSubmitForm(true);
    }
  };

  const formik = useFormik<ApplyJobData>({
    initialValues: {
      ...initialValues,
      position: careers[0].title,
    },
    onSubmit,
    validationSchema,
  });

  const {
    values,
    setFieldValue,
    handleSubmit,
    handleBlur,
    errors,
    touched,
    resetForm,
    isSubmitting,
    setSubmitting,
  } = formik;

  useEffect(() => {
    if (isSubmitted) {
      resetForm();
      setResumeFile(null);
      setPortfolioFile(null);
    }
  }, [isSubmitted]);

  return (
    <StyledContainer>
      <Title>Apply Now</Title>
      <form onSubmit={handleSubmit}>
        <div className="pb-18 justify-center w-full md:w-[550px] md:max-w-pageContainerSize px-pageContainerSpace xl:px-0 mx-auto ">

          <InputSection>
            <InputLabel>Position</InputLabel>
            <Input
              name="name"
              className="bg-opacity-0 bg-white"
              value={values.position}
              onChange={(e) => setFieldValue('position', e.target.value)}
              onBlur={handleBlur}
              disabled
            />
            {errors.position && touched.position && <InputMessage>{errors.position}</InputMessage>}
          </InputSection>
          <InputSection>
            <InputLabel>Full Name</InputLabel>
            <Input
              name="full_name"
              className="bg-opacity-0 bg-white"
              value={values.full_name}
              onChange={(e) => setFieldValue('full_name', e.target.value)}
              onBlur={handleBlur}
              // placeholder="Somboon Poonsook"
            />
            {errors.full_name && touched.full_name && <InputMessage>{errors.full_name}</InputMessage>}
          </InputSection>
          <InputSection>
            <InputLabel>Phone Number</InputLabel>
            <Input
              name="phone_number"
              className="bg-opacity-0 bg-white"
              value={values.phone_number}
              onChange={(e) => setFieldValue('phone_number', e.target.value)}
              onBlur={handleBlur}
              // placeholder="0899999999"
              placeholder="0900000000 or +66900000000"
              maxLength={16}
            />
            {errors.phone_number && touched.phone_number && <InputMessage>{errors.phone_number}</InputMessage>}
          </InputSection>
          <InputSection>
            <InputLabel>Email</InputLabel>
            <Input
              name="email"
              className="bg-opacity-0 bg-white"
              value={values.email}
              onChange={(e) => setFieldValue('email', e.target.value)}
              onBlur={handleBlur}
              // placeholder="abcdef@gmail.com"
            />
            {errors.email && touched.email && <InputMessage>{errors.email}</InputMessage>}
          </InputSection>
          <InputSection>
            <InputLabel>Expected Salary</InputLabel>
            <Input
              name="expected_salary"
              className="bg-opacity-0 bg-white"
              value={values.expected_salary}
              onChange={(e) => setFieldValue('expected_salary', e.target.value)}
              maxLength={6}
              onBlur={handleBlur}
            />
            {errors.expected_salary && touched.expected_salary && <InputMessage>{errors.expected_salary}</InputMessage>}
          </InputSection>
          <InputSection>
            <InputLabel>Resume</InputLabel>
            <p className="text-xs">PDF file</p>
            <input
              id="resume_input"
              name="resume"
              type="file"
              className="hidden"
              accept="application/pdf"
              onChange={(e) => onAddFile(e, 'resume')}
            />
            <label htmlFor="resume_input" className="flex justify-center">
            {resumeFile
              ? (
                <div className="w-full flex justify-between items-center px-4">
                  <div className="font-semibold text-orange">{resumeFile.name}</div>
                  <div className="p-2 border-orange border-[1px] rounded-md cursor-pointer text-xs flex justify-center">
                    Change File
                  </div>
                </div>
              )
              : (
              <div className="p-2 border-orange border-[1px] rounded-md cursor-pointer w-[200px] text-sm flex justify-center">
                Upload File
              </div>
              )
            }
            </label>
            {resumeError && <InputMessage className="text-center mt-2">Please add the Resume File</InputMessage>}
          </InputSection>
          <InputSection>
            <InputLabel>Portfolio (optional)</InputLabel>
            <p className="text-xs">PDF file</p>
            <input
              id="portfolio_input"
              name="portfolio"
              type="file"
              className="hidden"
              accept="application/pdf"
              onChange={(e) => onAddFile(e, 'portfolio')}
            />
            <label htmlFor="portfolio_input" className="flex justify-center">
              {portfolioFile
                ? (
                  <div className="w-full flex justify-between items-center px-4">
                    <div className="font-semibold text-orange">{portfolioFile.name}</div>
                    <div className="p-2 border-orange border-[1px] rounded-md cursor-pointer text-xs flex justify-center">
                      Change File
                    </div>
                  </div>
                )
                : (
                <div className="p-2 border-orange border-[1px] rounded-md cursor-pointer w-[200px] text-sm flex justify-center">
                  Upload File
                </div>
                )
              }
            </label>
          </InputSection>
          <div className="flex flex-col items-center">
            <p className="m-0 text-center text-sm">Total files size should be less than 10MB.</p>
            <p className="m-0 text-center text-sm">If your portfolio is large, you can send the email with it along with this form. </p>
            <p className="m-0 text-center text-sm">Please send it to: <a href="mailto:wanttowork@orangecapinnovative.com" className="text-orange">wanttowork@orangecapinnovative.com</a></p>
          </div>
          <div className="flex flex-col w-full justify-center">
            <div className="mt-8 mb-6">
              <p className="text-lg">Before submitting your application please review the points below:</p>
              <ul className="text-sm">
                <li>Please <a href="/recruitment-policy" target="_blank" className="text-orange">click here</a> to read the Orange Cap Innovative Candidate Data Privacy Notice.</li>
                <li>This Privacy Notice is not a contract, express or implied and it does not set terms or conditions of employment.</li>
              </ul>
              <p className="text-lg">By submitting this form you agree to the Orange Cap Innovative Candidate Data Privacy Notice provided above.</p>
            </div>
            {!isSubmitting && (
              <button
                type="button"
                onClick={() => handleSubmit()}
                className={`w-[180px] h-12 border-orange border-[2px] text-white bg-orange rounded-[5px] transition transform hover:scale-105 font-normal mx-auto text-base`}
              >
                Submit
              </button>
            )}
          </div>
          {isErrorSubmitForm && (
            <div className="w-full flex flex-col justify-center items-center gap-y-2">
              <span className="text-red-500 text-center font-semibold text-lg">We are so sorry!</span>
              <span className="text-red-400 text-center font-light text-base">There is some network issues, please try again later.</span>
            </div>
          )}
          {isSubmitting && (
            <div className="flex mx-auto w-2/3 mt-16 flex-col text-center">
              <img
                alt="Footer Logo"
                src={OCILogo}
                className="w-[40px] lg:w-[55px] self-center oci-loading"
              />
              <p className="mt-6">We're processing your information.</p>
              <p>This might take some time to complete, please stay on this page.</p>
            </div>
          )}
          {isSubmitted && (
            <div className="w-full flex flex-col justify-center items-center mt-8 gap-y-2">
              <img src={CheckMarkIcon} width={24} height={24} />
              <span className="text-orange text-center font-semibold text-lg">We've received your information.</span>
              <span className="text-[#616161] text-center font-light text-base">Our HR will get back to you soon!</span>
            </div>
          )}
        </div>
      </form>
    </StyledContainer>
  );
};

export default JobApplicationForm;
